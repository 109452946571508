<template>
  <li class="nav-item" :class="{ 'active': isActive(data.to) }">
    <a
      class="nav-link"
      data-toggle="collapse"
      :href="'#' + id(data.title)"
      role="button"
      :aria-expanded="isActive(data.to)"
      :aria-controls="id(data.title)"
      v-if="hasChild">
      <FeatherIcon v-if="data.icon !== ''" :type="data.icon" class="link-icon"/>
      <i v-if="data.el_icon && !data.el_icon.startsWith('fontawesome')" :class="data.el_icon"></i>
      <font-awesome v-else-if="data.el_icon && data.el_icon.startsWith('fontawesome')" :icon="data.el_icon.replace('fontawesome', '')" class="link-icon"/>
      <span class="link-title">{{ data.title }}</span>
      <FeatherIcon class="link-arrow" type="chevron-down" />
    </a>
    <router-link :to="data.to" class="nav-link" active-class="active" v-else>
      <FeatherIcon v-if="data.icon !== ''" :type="data.icon" class="link-icon" :style="{ color: data.color }"/>
      <i v-else-if="data.el_icon && !data.el_icon.startsWith('fa')" :class="data.el_icon" class="link-icon" :style="{ color: data.color }"></i>
      <font-awesome v-else :icon="data.el_icon.split(' ')[1]" class="link-icon" :style="{ color: data.color }"/>
      <span class="link-title">{{ data.title }}</span>
    </router-link>

    <div
      class="collapse"
      :class="{'show': isActive(data.to)}"
      :id="id(data.title)"
      v-if="hasChild">
      <ul class="nav sub-menu">
        <li class="nav-item" v-for="(childItem, i) in data.child" :key="'sub_'+i">
          <router-link :to="childItem.to" class="nav-link" active-class="active">
            {{ childItem.title }}
          </router-link>
          <!-- <a :href="childItem.to" class="nav-link" :class="{'active': isActive(childItem.to)}">
            {{ childItem.title }}
          </a> -->
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import slugify from 'slugify';

export default {
  name: 'SidebarItem',
  props: ['data'],
  computed: {
    hasChild() {
      return this.data.child && this.data.child.length > 0;
    },
  },
  methods: {
    id: (s) => slugify(s),
    isActive(link) {
      return this.$route.fullPath.includes(link);
    },
  },
};
</script>
<style>
.custom-icon {
  font-size: 16px;
}
</style>
